import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { PushNotification, PushNotificationActionPerformed, PushNotifications, PushNotificationToken } from '@capacitor/push-notifications';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class FcmService {

  regToken: any;

  constructor(
    private router: Router,
    private http: HttpClient
  ) { }

  public initPush() {
    if (Capacitor.platform != 'web') {
      this.registerPush();
    }

  }

  private registerPush() {
    PushNotifications.requestPermissions().then((permission) => {
      if (permission.receive) {
        PushNotifications.register();
      } else {

      }
    });

    PushNotifications.addListener('registration', (token: PushNotificationToken) => {
      console.log('My token : ' + JSON.stringify(token));
      this.regToken = token;
    })

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error : ' + JSON.stringify(error));
    })

    PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotification) => {
      console.log('Error : ' + JSON.stringify(notification));
    })

    PushNotifications.addListener('pushNotificationActionPerformed', async (notification: PushNotificationActionPerformed) => {
      const data = notification.notification.data;
      console.log('Action Performed : ' + JSON.stringify(notification.notification));
      if (data.detailsId) {
        this.router.navigateByUrl(`/home/${data.detailsId}`);
      }
      console.log('Error : ' + JSON.stringify(notification));
    })
  }

  async pushNotification() {
    this.http.post(`http://192.168.0.111:3000/API/v1/firebase`, { token: this.regToken });
  }

  async getImg(apiUrlEnd: string, imageName) {
    return await this.http.get(`https://hospital.api.teorainfotech.com/API/V1/${apiUrlEnd}/${imageName}`, { responseType: 'blob' }).toPromise();
  }

}
