import { Component, OnInit } from '@angular/core';
import { FcmService } from '../services/fcm.service';
import { saveAs } from 'file-saver';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss'],
})
export class DownloadComponent implements OnInit {

  constructor(
    private _fcmservice: FcmService,
    private _actRouter: ActivatedRoute
  ) { }

  ngOnInit() {
    this._actRouter.paramMap.subscribe(async (param: ParamMap) => {

      if (param.get('id')) {
        this.downloadDoc(param.get('id'))
      }
    });
  }

  downloadDoc(imageName) {
    if (imageName) {
      this._fcmservice.getImg('file-upload/doctor', imageName).then((res: any) => {
        saveAs(res, "document")
      }, (err) => {
        console.log("==er====", err);

      });
    }

  }

}
